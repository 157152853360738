import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImage } from "@life-without-barriers/gatsby-common"
import {
  IconArrowRight,
  Events,
  Variables,
  Link,
  Box
} from "@life-without-barriers/react-components"

import { StoryTileProps } from "../../../contentTypes"

const { black, youthPinkMedium } = Variables
const { simulateClickEvent } = Events

const Tile = styled(Link)`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  .Tile-title,
  .Tile-subtitle,
  .Tile-copy {
    color: ${black};
    transition: color 0.5s ease;
  }

  .Tile-copy {
    font-size: 16px;
    line-height: 1.5;
  }

  &:hover .Tile-title {
    color: ${youthPinkMedium};
  }
`

const StyledImage = styled(GatsbyImage)`
  left: 50%;
  object-fit: cover;
  position: relative;
  transform: translateX(-50%);
`

const StoryTile = ({
  image,
  title,
  subtitle,
  summarySentence,
  slug,
  imageAlt
}: StoryTileProps) => {
  return (
    <Box className="w-100 w-50-m w-33-l mv3">
      <Tile
        className="t-story-link db br2 overflow-hidden no-underline h-100"
        to={slug}
        tabIndex={0}
        onKeyDown={simulateClickEvent}
        title={`Read more about ${title}`}
        role="link"
      >
        <div className="flex flex-column normal h-100">
          <StyledImage image={getGatsbyImage(image)} alt={imageAlt || ""} />
          <div className="pa3 flex flex-column flex-auto">
            <h2 className="Tile-title fw8 ts-display-3 mb2">{title}</h2>
            <h3 className="Tile-subtitle copy fw8">{subtitle}</h3>
            <p className="Tile-copy">{summarySentence}</p>
          </div>
          <div className="ph3 pb4 fw6 mt2 flex items-baseline color-lwb-theme-medium">
            Read more{" "}
            <IconArrowRight
              height="8"
              width="5"
              color={youthPinkMedium}
              className="ml2"
            />
          </div>
        </div>
      </Tile>
    </Box>
  )
}

export default StoryTile
