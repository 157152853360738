import React from "react"
import styled from "styled-components"
import {
  PreStyledComponents,
  Variables
} from "@life-without-barriers/react-components"

import { iconSelector } from "../iconSelector"
import { SummaryBarItem } from "../../../contentTypes"

const { IconWrapper } = PreStyledComponents

const { youthPinkMedium, youthPinkXXXLight } = Variables

const Tile = styled.div`
  position: relative;
  max-width: 240px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: lightgray;
    right: 0;
  }
  &:last-child:after {
    display: none;
  }

  @media screen and (min-width: 60em) {
    width: 185px;
  }

  @media screen and (min-width: 75em) {
    width: 236px;
  }
`

const Title = styled.div`
  font-size: 11px;
  letter-spacing: 0.1rem;
`

export const SummaryBarBox = ({
  icon,
  height,
  title,
  value
}: SummaryBarItem) => {
  return (
    <Tile className={"fl flex flex-column items-center pt2 pb3"}>
      <div className="dn db-m db-l pb2 tc-l">
        <IconWrapper color={youthPinkXXXLight}>
          {iconSelector(icon, youthPinkMedium, height)}
        </IconWrapper>
      </div>
      <Title className="color-lwb-theme-x-dark fw4 ttu pb1 pt2 tc-l">
        {title}
      </Title>
      <div className="pt1 ph3 lh-copy b tc-l w-100">{value}</div>
    </Tile>
  )
}

export default SummaryBarBox
