import React from "react"
import {
  IconEmergency,
  IconAdoption,
  IconGuardianship,
  IconKinship,
  IconLongTerm,
  IconRespite,
  IconShortTerm,
  IconCake,
  IconFamily,
  IconBaby,
  IconClock,
  IconHeart
} from "@life-without-barriers/react-components"

export const iconSelector = (
  type: string,
  color: string,
  height?: string
): JSX.Element | undefined => {
  if (type === "emergency") {
    return <IconEmergency color={color} height={height} />
  }
  if (type === "adoption") {
    return <IconAdoption color={color} height={height} />
  }
  if (type === "guardianship") {
    return <IconGuardianship color={color} height={height} />
  }
  if (type === "kinship") {
    return <IconKinship color={color} height={height} />
  }
  if (type === "longterm") {
    return <IconLongTerm color={color} height={height} />
  }
  if (type === "respite") {
    return <IconRespite color={color} height={height} />
  }
  if (type === "shortterm") {
    return <IconShortTerm color={color} height={height} />
  }
  if (type === "cake") {
    return <IconCake color={color} height={height} />
  }
  if (type === "family") {
    return <IconFamily color={color} height={height} />
  }
  if (type === "baby") {
    return <IconBaby color={color} height={height} />
  }
  if (type === "clock") {
    return <IconClock color={color} height={height} />
  }
  if (type === "heart") {
    return <IconHeart color={color} height={height} />
  }
  return undefined
}
