import React from "react"
import { graphql } from "gatsby"
import {
  Banner,
  Box,
  Container,
  ContentfulRichText,
  LargeCTA,
  Helmet,
  Link,
  Row,
  routesObject,
  StandardButton,
  Section
} from "@life-without-barriers/react-components"
import { contentTypes as ct } from "@life-without-barriers/gatsby-common"
import styled from "styled-components"

import { SummaryBarBox } from "../../components/foster-care/SummaryBarBox/SummaryBarBox"
import Layout from "../../components/foster-care/Layout"
import {
  StoryTileProps,
  contentfulStorySummaryToStoryTile
} from "../../contentTypes"
import { FosterCareForm } from "@life-without-barriers/shared-contact-form"
import RelatedStoriesCollection from "../../components/foster-care/RelatedStoriesCollection/RelatedStoriesCollection"

interface ContentfulStoryIndex {
  edges: [
    {
      node: {
        foster_care_story: ct.FosterCareStorySummary[]
      }
    }
  ]
}

const contenfulStorySummaryToStorySummaryTile = (
  c: ContentfulStoryIndex
): StoryTileProps[] =>
  c.edges
    .filter((e) => e.node && e.node.foster_care_story)
    .map((e) => e.node.foster_care_story)
    .flatMap((stories) => stories.map(contentfulStorySummaryToStoryTile))

interface Props {
  location: { pathname: string }
  data: {
    site: ct.Site
    story: ct.FosterCareStoryFull
    relatedChildStoriesContentful?: ContentfulStoryIndex
    relatedCarerStoriesContentful?: ContentfulStoryIndex
    allCarerStoriesContentful?: ContentfulStoryIndex
  }
}

const SummaryBarContainer = styled.div`
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 50% 50%/10px 10px 10% 10%;
`

const MobileSummaryBarContainer = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
`

const MobileSummaryBarItem = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: lightgray;
    right: 0;
  }

  &:nth-child(even):after {
    display: none;
  }

  /* tablet - 3 column */
  @media screen and (min-width: 30em) and (max-width: 60em) {
    &:nth-child(even):after {
      display: block;
    }

    &:nth-child(3):after {
      display: none;
    }
  }
`

const Title = styled.div`
  font-size: 11px;
  letter-spacing: 0.1rem;
`

interface FeaturedAttribute {
  position: number
  title: string
  iconName: string
  value: string
}

const makeFeaturedAttributes = (
  storyAttributes:
    | ct.FosterCareStoryCarerAttributes
    | ct.FosterCareStoryChildAttributes
): Array<FeaturedAttribute> => {
  if (ct.isFosterCareCarerAttibutes(storyAttributes)) {
    return [
      {
        position: 1,
        title: "carers age",
        iconName: "cake",
        value: storyAttributes.carersAge
      },
      {
        position: 2,
        title: "relationship",
        iconName: "family",
        value: storyAttributes.relationship
      },
      {
        position: 3,
        title: "age of children",
        iconName: "baby",
        value: storyAttributes.ageOfChildren
      },
      {
        position: 4,
        title: "type of care",
        iconName: "clock",
        value: storyAttributes.typeOfCare.join(", ")
      },
      {
        position: 5,
        title: "needs of children",
        iconName: "heart",
        value: storyAttributes.needsOfChildren.join(",  ")
      }
    ]
  } else if (ct.isFosterCareChildAttibutes(storyAttributes)) {
    return [
      {
        position: 1,
        title: "age",
        iconName: "clock",
        value: storyAttributes.age
      },
      {
        position: 2,
        title: "age entered foster care",
        iconName: "baby",
        value: storyAttributes.ageEnteredFosterCare
      },
      {
        position: 3,
        title: "relationship to carer",
        iconName: "heart",
        value: storyAttributes.relationshipToCarer
      }
    ]
  } else {
    return []
  }
}

const attributeToSummaryBox = (attribute: FeaturedAttribute) => (
  <SummaryBarBox
    id={attribute.position}
    key={attribute.position}
    icon={attribute.iconName}
    title={attribute.title}
    value={attribute.value}
    height={"30"}
  />
)

// desktop
const WideSummaryBar = ({ items }: { items: Array<FeaturedAttribute> }) => (
  <SummaryBarContainer
    aria-label="Foster care profile information"
    className="dn flex-l center relative nb4 ph2-ns bg-white pv4"
  >
    {items.map(attributeToSummaryBox)}
  </SummaryBarContainer>
)

const MobileSummaryBar = ({ items }: { items: Array<FeaturedAttribute> }) => (
  <MobileSummaryBarContainer className="dn-l flex flex-row flex-wrap pv3 center">
    {items.map((attribute) => (
      <MobileSummaryBarItem
        key={attribute.position}
        className="fl w-50 w-33-m ph3 ph4-ns pv2"
      >
        <Title className="color-lwb-theme-dark ttu pb1 tc-l">
          {attribute.title}
        </Title>
        <div className="pt1 lh-copy b tc-l">{attribute.value}</div>
      </MobileSummaryBarItem>
    ))}
  </MobileSummaryBarContainer>
)

const StoryTemplate = ({
  location,
  data: {
    story,
    site: { siteMetadata },
    relatedCarerStoriesContentful,
    allCarerStoriesContentful,
    relatedChildStoriesContentful
  }
}: Props) => {
  const relatedStories = relatedChildStoriesContentful
    ? contenfulStorySummaryToStorySummaryTile(relatedChildStoriesContentful)
    : relatedCarerStoriesContentful
    ? contenfulStorySummaryToStorySummaryTile(relatedCarerStoriesContentful)
    : []

  const extrasStoriesRequiredCount =
    3 - relatedStories.length > 0 ? 3 - relatedStories.length : 0

  const extraRelatedStories =
    allCarerStoriesContentful && extrasStoriesRequiredCount
      ? contenfulStorySummaryToStorySummaryTile(allCarerStoriesContentful)
          .filter(
            (c) =>
              c.title.toLocaleLowerCase() !== story.title.toLocaleLowerCase()
          )
          .slice(0, extrasStoriesRequiredCount)
      : []

  const allRelatedWithExtrasWithDuplicates = relatedStories
    .concat(extraRelatedStories)
    .filter(
      (c) => c.title.toLocaleLowerCase() !== story.title.toLocaleLowerCase()
    )
  const allRelatedWithExtras = Array.from(
    new Set(allRelatedWithExtrasWithDuplicates.map((item) => item.title))
  ).map((title) => {
    return allRelatedWithExtrasWithDuplicates.find(
      (item) => item.title === title
    )
  }) as StoryTileProps[]

  return (
    <Layout>
      <Helmet
        title={`${story.title} | ${siteMetadata.title}`}
        description={story.introText}
        image={story.bannerImage.gatsbyImageData}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        isArticle={true}
      />
      <Banner
        title={story.title}
        subTitle={story.introText}
        image={story.bannerImage.gatsbyImageData}
        share={true}
        breadcrumbs={[
          routesObject.fosterCareHome,
          {
            to: "/foster-care/stories",
            text: "Stories from care",
            title: "Stories from care"
          }
        ]}
      ></Banner>
      <Container>
        <FeaturedAttributesBanner attributes={story.attributes} />
        <Row>
          <Box className="flex justify-center center">
            <ContentfulRichText body={story.body} centered={true} />
          </Box>
        </Row>
      </Container>
      <Section background="bg-lwb-theme-xxx-light">
        <Container>
          <LargeCTA
            headingText="Got more questions?"
            contentBlock={{
              kind: "inline",
              elements: (
                <p>
                  Our friendly and helpful fostering specialists are ready to
                  answer your questions. Whether you&apos;re looking to start
                  the process, get some answers or want more information, our
                  team is waiting for you to connect.
                </p>
              )
            }}
            ctaButton={{
              kind: "form",
              buttonText: "START YOUR FOSTER CARE JOURNEY",
              form: (
                <FosterCareForm
                  formId="care-journey"
                  formTitle="We’re here to help"
                  formSubtitle="Our friendly and helpful foster care specialists are ready to answer your questions. Whether you're looking to start to process or just want more info, our team can help."
                  successTitle="We got your enquiry!"
                  successMessage="Our team will be in touch within the next business day."
                />
              )
            }}
          ></LargeCTA>
        </Container>
      </Section>
      <Container>
        <Row>
          <RelatedStoriesCollection
            stories={allRelatedWithExtras}
            reducedTopMargin={true}
          />
        </Row>
        <div className="tc ph3 ph4-ns">
          <Link
            className="fw8 dib mt3 mt4-ns"
            to="/foster-care/stories"
            title="Back to all foster care stories"
          >
            <StandardButton className="w-100 w-auto-ns">
              Back to all stories
            </StandardButton>
          </Link>
        </div>
      </Container>
    </Layout>
  )
}
const FeaturedAttributesBanner = ({
  attributes
}: {
  attributes:
    | ct.FosterCareStoryCarerAttributes
    | ct.FosterCareStoryChildAttributes
}) => {
  const summaryAttributes = makeFeaturedAttributes(attributes)
  return (
    <Row className="mb5-l">
      <WideSummaryBar items={summaryAttributes} />
      <MobileSummaryBar items={summaryAttributes} />
    </Row>
  )
}

export const query = graphql`
  fragment ChildStories on Query {
    relatedChildStoriesContentful: allContentfulFosterCareChildAttributes {
      edges {
        node {
          foster_care_story {
            id
            slug
            title
            bannerImage {
              gatsbyImageData(quality: 90, width: 500, aspectRatio: 1.78)
            }
            subtitle
            description
            introText
          }
        }
      }
    }
  }

  fragment RelatedCarerStories on Query {
    relatedCarerStoriesContentful: allContentfulFosterCareCarerAttributes(
      filter: { typeOfCare: { in: $typeOfCare } }
    ) {
      edges {
        node {
          foster_care_story {
            id
            slug
            title
            bannerImage {
              gatsbyImageData(quality: 90, width: 500, aspectRatio: 1.78)
            }
            subtitle
            description
          }
        }
      }
    }
  }

  fragment AllCarerStories on Query {
    allCarerStoriesContentful: allContentfulFosterCareCarerAttributes {
      edges {
        node {
          foster_care_story {
            id
            slug
            title
            bannerImage {
              gatsbyImageData(quality: 90, width: 500, aspectRatio: 1.78)
            }
            subtitle
            description
          }
        }
      }
    }
  }

  query ($id: String, $isChildStory: Boolean!, $typeOfCare: [String]) {
    story: contentfulFosterCareStory(id: { eq: $id }) {
      ...FosterCareStoryFull
    }
    ...RelatedCarerStories @skip(if: $isChildStory)
    ...AllCarerStories @skip(if: $isChildStory)
    ...ChildStories @include(if: $isChildStory)
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default StoryTemplate
