import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { PreStyledComponents } from "@life-without-barriers/react-components"
import shuffle from "lodash.shuffle"

import StoryTile from "../StoryTile/StoryTile"
import { StoryTileProps } from "../../../contentTypes"

const { GridWrapper } = PreStyledComponents

type Status<T> = { kind: "Loading" } | { kind: "Loaded"; value: T }

const Title = styled.div`
  font-size: 11px;
  letter-spacing: 0.1rem;
`

interface RelatedStoriesCollectionProps {
  stories?: StoryTileProps[]
  title?: string
  reducedTopMargin?: boolean
}

const byTitleCaseInsensitive = (a: StoryTileProps, b: StoryTileProps): number =>
  a.title.localeCompare(b.title, undefined, {
    sensitivity: "accent"
  })

const RelatedStoriesCollection = (props: RelatedStoriesCollectionProps) => {
  const [stories, setStories] = useState<Status<StoryTileProps[]>>({
    kind: "Loading"
  })

  useEffect(() => {
    switch (stories.kind) {
      case "Loading": {
        setStories({
          kind: "Loaded",
          value: shuffle(props.stories).slice(0, 3).sort(byTitleCaseInsensitive)
        })
      }
    }
  })

  switch (stories.kind) {
    case "Loading":
      return <></>
    case "Loaded":
      return (
        <div className={props.reducedTopMargin ? "mt3-l" : "mt4"}>
          <Title className="color-lwb-grey ttu f7 ph0">
            {props.title ?? "More Related Stories"}
          </Title>
          <GridWrapper className="mt2-ns flex-ns flex-wrap-ns">
            {stories.value.map((story, i) => {
              return <StoryTile {...story} key={i} />
            })}
          </GridWrapper>
        </div>
      )
  }
}

export default RelatedStoriesCollection
